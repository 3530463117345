<template>
  <temeno-circle-button
    action
    title="persönlichen Freigabelink in die Zwischenablage kopieren"
    @click="copy"
  >
    {{ copied ? '$approve' : '$link' }}
  </temeno-circle-button>
</template>

<script>
import copy from 'copy-to-clipboard';

export default {
  name: "ShareLinkCopyButton",
  components: {
    TemenoCircleButton: () => import("@/components/TemenoCircleButton"),
  },
  props: {
    value: String,
  },
  data() {
    return {
      copied: false,
    };
  },
  computed: {
    url() {
      return this.value
        ? this.$store.getters["connection/serverBaseUrl"] + "/go/" + this.value
        : null;
    },
  },
  methods: {
    copy() {
      copy(this.url);
      this.copied = true;
      setTimeout(this.reset, 1000);
    },
    reset() {
      this.copied = false;
    },
  },
}
</script>
